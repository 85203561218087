<template>
  <div class="card">
    <h3 class="card-title">Ver licencias por gerencia</h3>
    <div class="cols3">
      <suggest
        id="gerencias"
        label="Gerencias"
        placeholder="Buscar gerencia.."
        class=""
        :data="gerencias"
        :items="itemsGerencia"
        @selected="gerenciaSelected"
        :required="enviado && !gerencia"
      ></suggest>
      <suggest
        id="tipo_licencia"
        label="Licencia"
        placeholder="Buscar licencia.."
        class=""
        :data="tiposLicencia"
        :items="tipoLicenciaList"
        @selected="tipoLicenciaSelected"
      ></suggest>
      <pd-input id="periodo" v-model="periodo" label="Período" :select="true">
        <template slot="options">
          <option :value="null">Todos</option>
          <option v-for="(year, i) in years" :key="i" :value="year">{{ year }}</option>
        </template>
      </pd-input>
    </div>

    <button type="button" class="btn btn-primary" @click="buscarPorEmpleado">
      Buscar
    </button>
    <div class="my-2">
      <table-list
        :showFilter="false"
        ref="tablelist"
        :data="licencias"
        :header="headers"
        :body="body"
        @rowClicked="showLicencia"
        :actionButton="actionButton"
      ></table-list>
    </div>
  </div>
</template>
<script>
import Suggest from "@/components/elements/Suggest";
import TableList from "@/components/elements/TableList";
import { TiposLicenciasServices } from "@/modules/tipos_licencias/services/TiposLicenciasServices";
import { LicenciasServices } from "@/modules/licencias/services/LicenciasServices";
import { GerenciaServices } from "@/modules/gerencias/services/GerenciaServices";
import Swal from "sweetalert2";
import XLSX from "xlsx";
export default {
  name: "VerPorGerencia",
  components: {
    Suggest,
    TableList,
  },
  data() {
    return {
      licencias: [],
      headers: LicenciasServices.headers(),
      body: LicenciasServices.body(),
      enviado: false,
      actionButton: {
        text: "Exportar selección a excel",
        action: () => this.downloadXLS()
      },

      periodo: null,

      selectLicencia: null,
      tiposLicencia: null,
      tipoLicencia: null,
      tipoLicenciaList: TiposLicenciasServices.dataList,

      gerencia: null,
      gerencias: null,
      itemsGerencia: GerenciaServices.dataList(),
    };
  },
  computed: {
    years: function () {
      let currentYear = new Date().getFullYear();
      let array = [];
      for (let index = 2019; index <= currentYear; index++) {
        array.push(index);
      }
      return array;
    },
  },
  mounted() {
    GerenciaServices.api.getAll().then((gerencias) => (this.gerencias = gerencias));
    TiposLicenciasServices.api
      .getAll()
      .then((tiposLicencia) => (this.tiposLicencia = tiposLicencia));
  },

  methods: {
    gerenciaSelected(gerencia) {
      this.gerencia = gerencia;
    },
    tipoLicenciaSelected(tipoLicencia) {
      this.tipoLicencia = tipoLicencia;
    },
    buscarPorEmpleado() {
      this.enviado = true;
      if (!this.gerencia) {
        Swal.fire("Error", "Seleccione un empleado", "error");
        return;
      }
      LicenciasServices.api
        .buscarPorGerencia(this.gerencia, this.tipoLicencia, this.periodo)
        .then((r) => {
          this.licencias = r.licencias;
        })
        .catch((e) => {
          Swal.fire("Error", `${e}`, "error");
        });
    },
    showLicencia(licencia) {
      console.log(licencia);
      // this.$router.push({name: 'VerLiquidacion', params:{id : licencia.id}})
    },
    downloadXLS(){
      const formattedJSON = this.formatFilteredDataForXLS(this.$refs['tablelist'].filteredData)
      this.exportExcel(formattedJSON)
    },
    formatFilteredDataForXLS(filteredData){
      const formattedArray = []
      for(const row of filteredData){
        const data = {
          "Nombre": row.persona.nombre,
          "CUIL": row.persona.cuil,
          "Tipo de licencia": row.tipo_licencia_descripcion,
          "Convenio": row.tipo_licencia_convenio,
          "Artículo": row.tipo_licencia_articulo,
          "Período": row.periodo,
          "Desde": row.desde,
          "Hasta": row.hasta,
          "Días": row.cantidad_dias,
          "Gozadas": row.gozados,
          "Corresponden": (row.corresponden !== null) ? row.corresponden : 'Sin tope',
          "Pendientes": (row.pendientes !== null) ? row.pendientes : 'Sin tope',
          "Fecha de solicitud": row.fecha_solicitud,
          "Días trabajados": row.dias_trabajados,
          "Fecha probable de parto": row.fecha_probable_parto,
          "Días previos a parto": row.dias_previos,
        }
        formattedArray.push(data)
      }
      return formattedArray
    },
    exportExcel(dataToExport) {
      console.log(dataToExport)
      let data = XLSX.utils.json_to_sheet(dataToExport)
      const workbook = XLSX.utils.book_new()
      const filename = 'Licencias por gerencia'
      XLSX.utils.book_append_sheet(workbook, data, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    }
  },
};
</script>
